import React from 'react'
import Helmet from 'react-helmet'
import Content, { HTMLContent } from '../components/Content'
import { Container } from 'reactstrap'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'

export const BlogPostTemplate = ({
  image,
  content,
  contentComponent,
  title,
  description,
  helmet
}) => {
  const PostContent = contentComponent || Content

  return (
    <Layout>
      <section className="section md-page" style={{ paddingTop: 120 }}>
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={description || title} />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description || title} />
          <meta property="og:image" content={image} />
          <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
        <Container>
          <div>
            <img
              src={image}
              alt="Blog post featured image"
              style={{
                width: '100%',
                maxHeight: 500,
                marginBottom: '3rem',
                objectFit: 'contain'
              }}
            />
          </div>
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <h1 className="title is-size-2 has-text-weight-bold is-bold-light">
                {title}
              </h1>
              <PostContent content={content} />
            </div>
          </div>
        </Container>
      </section>
    </Layout>
  )
}

export default props => {
  const { markdownRemark: post } = props.data

  return (
    <BlogPostTemplate
      contentComponent={HTMLContent}
      content={post.html}
      helmet={<Helmet title={`Blog | ${post.frontmatter.title}`} />}
      title={post.frontmatter.title}
      description={post.frontmatter.description}
      image={post.frontmatter.thumbnail.publicURL}
    />
  )
}

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        thumbnail {
          publicURL
        }
      }
    }
  }
`
